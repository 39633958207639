/* eslint-env browser */
/*
 * Small overlays of content.
 *
 * https://getbootstrap.com/docs/3.4/javascript/#popovers
 */
// Required dependency
require('./bootstrap-tooltip.jquery');

require('bootstrap/js/popover');
